import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'
import useUtms from 'src/hooks/useUtms'

import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import pageContext from './pageContext.json'

import Hero from './sections/hero/_index'
import MelhoresOpcoes from './sections/melhores-opcoes/_index'
import InvestFacil from './sections/invest-facil/_index'
import FundosDisponiveis from 'src/components/InvestmentsFilter/fundos-disponiveis/_index'
import ConhecaAsVantagens from './sections/conheca-as-vantagens/_index'
import OmelhorAppDeInvestimentos from './sections/o-melhor-app-de-investimentos/_index'
import Blog from './sections/blog/_index'
import Faq from './sections/faq/_index'
import qrcodeAberturaDeContaInvestimentoEmpresarial from './assets/images/qrcode-abertura-de-conta-investimento-empresarial.png'

const InvestimentoEmpresarial = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })
  const type = 'PJ'

  const domReady = useDomReady()
  const modalOpen = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isPJ
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='<span class="d-md-block">Escaneie o QR Code,</span> acesse o app'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para a loja de aplicativos.
        Aí é só baixar o app do Inter Empresas, abrir a sua conta digital e investir.'
        qrCode={qrcodeAberturaDeContaInvestimentoEmpresarial}
      />
    </Modal>
  )

  const openAccountPJFormModal = () => {
    window.location.href = utmLink
  }

  return (
    <Layout handleHeaderOpenAccount={() => openAccountPJFormModal()} pageContext={pageContext}>
      {modalOpen}
      <Hero setIsOpen={setIsOpen} />
      <MelhoresOpcoes />
      <InvestFacil setIsOpen={setIsOpen} />
      <FundosDisponiveis type={type} searchBg='bg-white' />
      <ConhecaAsVantagens />
      <OmelhorAppDeInvestimentos setIsOpen={setIsOpen} />
      <Blog />
      <Faq faq={pageContext.structuredData.faq} />
    </Layout>
  )
}

export default InvestimentoEmpresarial
